import { isUndefined } from 'util';

import { Reducer } from 'redux';

import { RouteName } from '../routes';

import { ActionObject, ActionType, NavBarState, RoutesState, SideBarState } from './actions';

const initialRoutesState: RoutesState = {
  activeRouteName: RouteName.HOME,
};

const initialSideBarState: SideBarState = {
  isOpen: true,
};

const initialNavBarState: NavBarState = {
  title: RouteName.HOME,
  color: 'transparent',
  isOpen: false,
  dropdownOpen: false,
};

export const routesReducer: Reducer<RoutesState, ActionObject> = (state = initialRoutesState, action): RoutesState => {
  switch (action.type) {
    case ActionType.ROUTES_SET_ACTIVE:
      return {
        ...state,
        activeRouteName: action.name,
        pdfUrl: action.pdfUrl,
      };
    default:
      return state;
  }
};

export const sideBarReducer: Reducer<SideBarState, ActionObject> = (state = initialSideBarState, action): SideBarState => {
  switch (action.type) {
    case ActionType.SIDE_BAR_TOGGLE_IS_OPEN:
      return {
        ...state,
        isOpen: isUndefined(action.isOpen) ? !state.isOpen : action.isOpen,
      };
    default:
      return state;
  }
};

export const navBarReducer: Reducer<NavBarState, ActionObject> = (state = initialNavBarState, action): NavBarState => {
  switch (action.type) {
    case ActionType.NAV_BAR_UPDATE_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case ActionType.NAV_BAR_TOGGLE_DROPDOWN:
      return {
        ...state,
        dropdownOpen: !state.dropdownOpen,
      };
    case ActionType.NAV_BAR_TOGGLE:
      const isOpen = typeof action.isOpen !== 'undefined' ? action.isOpen : !state.isOpen;
      return {
        ...state,
        isOpen,
        color: isOpen ? 'dark' : 'transparent',
      };
    default:
      return state;
  }
};
