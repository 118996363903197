import { Action } from '../../redux/actions';
import { RouteName } from '../routes';

/**
 * Action types
 */

export enum ActionType {
  ROUTES_SET_ACTIVE = 'ROUTES_SET_ACTIVE',

  SIDE_BAR_TOGGLE_IS_OPEN = 'SIDE_BAR_TOGGLE_IS_OPEN',

  NAV_BAR_UPDATE_TITLE = 'NAV_BAR_UPDATE_TITLE',
  NAV_BAR_TOGGLE_DROPDOWN = 'NAV_BAR_TOGGLE_DROPDOWN',
  NAV_BAR_TOGGLE = 'NAV_BAR_TOGGLE',
}

/**
 * Action entities
 */

export interface RoutesState {
  activeRouteName: RouteName;
  pdfUrl?: string;
}

export interface SideBarState {
  isOpen: boolean;
}

export interface NavBarState {
  title: string;
  color: string;
  isOpen: boolean;
  dropdownOpen: boolean;
}

/**
 * Action creators
 */
interface RouteSetActiveAction extends Action<ActionType> {
  name: RouteName;
  pdfUrl?: string;
}

interface SideBarToggleIsOpenAction extends Action<ActionType> {
  isOpen?: boolean;
}

interface NavBarUpdateTitleAction extends Action<ActionType> {
  title: string;
}

interface NavBarToggleAction extends Action<ActionType> {
  isOpen?: boolean;
}

export type ActionObject = RouteSetActiveAction & SideBarToggleIsOpenAction & NavBarUpdateTitleAction;

export const routesSetActive = (name: RouteName, pdfUrl: string): RouteSetActiveAction => ({
  type: ActionType.ROUTES_SET_ACTIVE,
  name,
  pdfUrl,
});

export const sideBarToggleIsOpen = (isOpen?: boolean): SideBarToggleIsOpenAction => ({
  type: ActionType.SIDE_BAR_TOGGLE_IS_OPEN,
  isOpen,
});

export const navBarUpdateTitle = (title: string): NavBarUpdateTitleAction => ({
  type: ActionType.NAV_BAR_UPDATE_TITLE,
  title,
});

export const navBarToggleDropDown = (): Action<ActionType> => ({
  type: ActionType.NAV_BAR_TOGGLE_DROPDOWN,
});

export const navBarToggle = (isOpen?: boolean): NavBarToggleAction => ({
  type: ActionType.NAV_BAR_TOGGLE,
  isOpen,
});
