import { Maybe } from 'monet';
import * as React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Navbar, Container, NavbarBrand, NavLink, Modal, ModalHeader, ModalBody, ModalFooter, Button, NavbarText } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { logoutUser } from '../user';

import { toggleNavBar, toggleNavBarDropDown, toggleSideBarIsOpen } from './data/actions';
import { RouteName, routesList } from './routes';

type UserGuideModalProps = {
  pdfUrl: string;
  isOpen: boolean;
  toggle: () => void;
};

const UserGuideModal = ({ pdfUrl, isOpen, toggle }: UserGuideModalProps) => {
  return (
    <Modal className="full-screen-modal" contentClassName="h-100 w-100" isOpen={isOpen} toggle={toggle}>
      <ModalHeader>User Guide</ModalHeader>
      <ModalBody>
        <embed src={pdfUrl} width="100%" height="100%"></embed>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

class Component extends React.Component<Props, { isModalOpen: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  protected static getPanelTitle(routeName: RouteName) {
    return Maybe.fromUndefined(routesList.find((r) => r.name === routeName))
      .map((r) => r.name.toString())
      .getOrElse('NOC Dashboard');
  }

  toggleUserGuideModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  render() {
    const { pdfUrl } = this.props.routes;
    return (
      <div>
        <Navbar color={this.props.navBar.color} expand="lg" className={this.props.navBar.color === 'transparent' ? 'navbar-transparent ' : ''}>
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-toggle">
                <button type="button" className="navbar-toggler" onClick={() => this.props.toggleSideBarIsOpen()}>
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand>
                {Component.getPanelTitle(this.props.routes.activeRouteName)}{' '}
                {!!pdfUrl && (
                  <Icon.QuestionCircleFill style={{ cursor: 'pointer', color: '#34a8eb' }} className="lg" onClick={this.toggleUserGuideModal} />
                )}
              </NavbarBrand>
            </div>
            <div className="d-flex justify-content-end">
              <NavbarText>
                {this.props.user.profile.firstName} {this.props.user.profile.lastName} ({this.props.user.profile.userName})
              </NavbarText>
              <NavLink id="logout-button" to="/" className="nav-link btn-rotate clickable" onClick={() => this.props.logoutUser()}>
                <p>Logout</p>
              </NavLink>
            </div>
          </Container>
        </Navbar>
        <UserGuideModal pdfUrl={pdfUrl || ''} isOpen={this.state.isModalOpen} toggle={this.toggleUserGuideModal} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      toggleSideBarIsOpen,
      toggleNavBarDropDown,
      toggleNavBar,
      logoutUser,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  routes: state.routes,
  navBar: state.navBar,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
